@import url("./../node_modules/bootstrap/dist/css/bootstrap.min.css");

.container {
	width: 100%;
	max-width: 1000px;
}

header {
	
.container {
	text-align: center;
	padding: 20px;
}

.title-container {
	background-color: #000;
	color: #fff;
	padding: 32px;
	text-align: center;
}
}

.first-cta {
    text-align: center;

	 .cta {
		padding: 40px 0px;
  }

  h4 {
	padding: 42px;
}
}



.strengths {
    padding: 40px 0px;
    background: #DEE2E6;
}

.try {
    padding: 80px 0px;
}

.img-container {
    padding-bottom: 38px;
    text-align: center;
	 img {
		width: 100%;
  }
}


.footer {
    background: #ffffff;

	 .card {
		margin-bottom: 48px;
  }

  form {
	padding: 50px;
	background: #000;
	color: #fff;
	max-width: 700px;
	margin: auto;
	width: 100%;
  }

.card-form {
	background: none;
	border: none;
	margin-bottom: 0px;
}
}

.panel-image {
	max-width: 600px;
	width: 100%;
}

.close {
	background: none;
    border: none;
    font-size: 20px;
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}

@media (max-width: 767.98px) { 
	.modal-dialog {
		margin: 0px;
		/* border-radius: 0px; */
  }
  
  .modal-header {
		border-radius: 0px;
  }
  
  .modal-content {
		border-radius: 0px;
  }
  
  .modal-dialog {
		max-height: 100vh;
		height: 100%;
  }
  
  .modal-content {
		height: 100%;
  }
  
  .modal-body {
		overflow: scroll;
  }

  section.try {
	padding: 20px 0px;
}

.img-container {
	padding-bottom: 10px;
}

.first-cta {
 h4 {
  padding: 20px;
}
}


}

@media (max-width: 1199.98px) {
	.float {
		width: 80px;
		height: 80px;
		font-size: 48px;
	}
 }